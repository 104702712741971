.mainFooter {
  display: flex;
  flex-direction: column;

  // background: #271f30;
  .container {
    padding: 0 15px;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  div {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;

    li {
      margin: 16px 0 2px;
      display: flex;
      flex-direction: column;
      max-width: max-content;
      transition: 0.2s ease-in-out;
      border-bottom: 1px solid white;
    }
    li:hover {
      border-bottom: 1px solid #a0a0a0;
    }
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid white;
  }
  @media (max-width: 960px) {
    .container {
      padding: 20px 0;
      flex-direction: column;
      text-align: left;
    }
    nav {
      padding: 20px;
      flex-direction: column;

      .helpline * {
        border-bottom: none;
      }
      .helpline div {
        margin: 0 0 20px;
        .footerNumbers {
          margin: 0;
        }
      }
      .helpline h4 {
        margin: 20px 0;
      }

      div {
        flex-direction: column;
        margin: 0 0 20px;
        border-bottom: 0.5px solid #e0e0e0;
        li {
          padding: 10px 0 10px;
        }
      }
    }
    span {
      margin-top: 40px;
    }
  }
  @media (max-width: 600px) {
    .container {
      padding: 0;
    }
    nav {
      padding-bottom: 0;
      div {
        margin: 0 0 10px;
        li {
          padding: 4px 0;
          margin: 10px 0 2px;
        }
      }
    }
  }
  .lang {
    cursor: pointer;
  }
}
